<template>

  <div class="activity_item" v-if="data?.id">
    
    <div href="#" :class="'item ' + (data.is_new ? 'is_new' : '')">
      <div class="item_table">
        <div class="col_one">
          <NuxtLink :class="'user_img ' + (data.item_user.is_premium ? 'is_premium ' : '') + (data.item_user.is_premier ? 'is_premier' : '')" :to="data.item_user.link">
             <img :alt="data.item_user.name" :src="data.item_user.avatar_small">
             <div :class="'user_range u_range_' + data.item_user.status"></div>
          </NuxtLink>
          <div class="user_info more_users_link">
            <NuxtLink class="user_name" :to="data.item_user.link">
              {{ data.item_user.name }}
            </NuxtLink>

              <span>
              {{ data.action ? $t(data.action) : '' }}
              </span>

          </div>

        </div>
        <div class="col_two">
          <i class="icon_hr"></i>          
          <i :class="data.icon"></i>
          <i class="icon_hr"></i>
        </div>
        <div class="col_three">

          <template v-if="data.type == 'like_comment' || data.type == 'comment_game' || data.type == 'comment_week' || data.type == 'comment_breeder' || data.type == 'comment_blog' || data.type == 'comment_seed' || data.type == 'comment_nutrient' || data.type == 'comment_nut'">

            <NuxtLink :to="data.link">
               <div class="comment_box" v-html="data.comment_text"></div>
            </NuxtLink>

          </template>
          <template v-else-if="data.type == 'follow_diary' || data.type == 'add_vote' || data.type == 'add_week' || data.type == 'like_week'">

            <NuxtLink :to="data.link">
              <div class="report_box">
                <div class="report_avatar">
                  <img :alt="data.report_name" :src="data.report_avatar">
                  <i v-if="data.report_awards" :title="data.report_awards.name" :class="'icon-' + data.report_awards.icon + ' score_diary'"></i>
                  <i v-else :title="$constants.growScoreText[data.report_progress_str] ? $t($constants.growScoreText[data.report_progress_str]) : ''" :class="'icon-' + data.report_progress_str + ' score_diary'"></i>
                </div>
                <div class="report_name">
                  {{ data.report_name }}


                  <span v-if="data.type == 'like_week'" :class="'item_faza faza_' + data.post_faza">
                    <div class="faza_name">{{ $constants.typeFazaShort[data.post_faza] ? $t($constants.typeFazaShort[data.post_faza]) : '' }}</div>
                    {{ data.post_num }}
                  </span>

                </div>

              </div>
            </NuxtLink>

          </template>
          <template v-else-if="data.type == 'follow_user'">
            <NuxtLink :to="data.item_following.link">
              <div class="user_box">
                <div :class="'user_img ' + (data.item_following.is_premium ? 'is_premium ' : '') + (data.item_following.is_premier ? 'is_premier' : '')">
                  <img :alt="data.item_following.name" :src="data.item_following.avatar_small">
                  <div :class="'user_range u_range_' + data.item_following.status"></div>
                </div>
                <div class="user_info">
                  <div class="user_name">
                    {{ data.item_following.name }}
                  </div>
                </div>

              </div>
            </NuxtLink>

          </template>

        </div>
      
      </div>
    </div>

  </div>

</template>

<script>
 

export default {   
  components: {   

  },
  props: [
    'data'
    //data:{
      //type: Object
    //}
  ],
  data () {
    return {            
    }
  }, 
  created() {    
    // this.query = this.$route.query.q;    
  },
  methods: {
     
  }
}
</script>

<style scoped>
 
  
  
.activity_item {

  padding: 7px 0px;
  margin-bottom: 0px;
  /* border-radius: 3px; */
  border-bottom: 1px var(--un-element-color-gray) solid;

}

.activity_item:last-child {
  border-bottom: 0px; 
}


.activity_item > .item.is_new {
    background-color: rgba(119, 175, 74, 0.12);
}
.activity_item > .item .is_new {
    background: rgb(119, 175, 74);
    content: "";
    position: absolute;
    display: block;
    left: -1.2rem;
    width: 10px;
    height: 10px;
    /* margin-top: -61px; */
    z-index: 100;
    top: calc(50% - 6px);
    border-radius: 30px;
}
.activity_item > .last_update{
    text-align: center;
    padding: 0rem 1.5rem 1.5rem 1.5rem;
    color: var(--un-text-color);
    font-weight: bold;
    margin-top: 20px;
}

.activity_item .item_table {
    display: table;
    width: 100%;
}
.activity_item .item:last-child {
  border: 0px;
}

.activity_item > .item .col_one {
    display: table-cell;
    width: 40%;
    vertical-align: middle;
    position: relative;
}

.activity_item > .item .col_two {
    display: table-cell;
    width: 20%;
    text-align: center;
    color: var(--un-element-color-gray);
    vertical-align: middle;
}

.activity_item > .item .col_two i{
    vertical-align: middle;
    font-size: 1.4rem;
    margin-left: 5px;
    margin-right: 5px;
    line-height: 1.3rem;
}

.activity_item > .item .col_two .icon_hr{
    display: inline-block;
    width: 20px;
    height:1px;
    border-bottom: 1px var(--un-element-color-gray) solid;
}

.activity_item > .item.is_new .col_two{
    color: var(--un-primary-back-color);
}

.activity_item > .item.is_new .col_two .icon_hr{
    border-bottom: 1px var(--un-primary-back-color) solid;
}
.activity_item > .item .col_three {
    display: table-cell;
    width: 40%;
    vertical-align: middle;

}

@container pb (max-width: 600px){
  .activity_item > .item .col_two .icon_hr{
    display: none;
  }
}

/* USER */

.activity_item > .item .user_img {
    width: 39px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
}

.activity_item > .item .user_img img {
    width: 100%;
    border-radius: 200px;
}

.activity_item > .item .user_img .user_range {
    position: absolute;
    bottom: 4px;
    right: -3px;
    font-size: 1.5rem;
    margin-right: 0px;
}

.activity_item > .item .user_name {
    display: inline-block;
    color: #4183c4;
    margin-right: 10px;
}

.activity_item > .item .user_info {
    display: inline-block;

    width: calc(100% - 70px);
}
.activity_item > .item .user_action {
    display: inline-block;
    /* color: #494949; */
}
.activity_item > .item .comment_box {
    /* color: var; */
    overflow-wrap: break-word;
}



/* MORE USERS */

.more_users {
    display: inline-block;
    width: 70px;
    text-align: center;
}

.more_users .name {
    width: 100%;
    white-space: nowrap;
    overflow: hidden; /* ???????? ???, ??? ?? ?????????? ? ??????? *//* background: #fc0; */
    /* ???? ???? *//* padding: 5px; */
    /* ???? ?????? ?????? */
    text-overflow: ellipsis; /* ????????? ?????????? */
}

.more_users .avatar {
    width: 80%;
    margin: 0px;
    display: inline-block;
}

.more_users .avatar img {
    width: 100%;
    border-radius: 100px;
}

/* REPORT BOX */


.report_box{

}

.report_box .report_avatar {
    display: inline-block;
    width: 40px;
    vertical-align: middle;
    border-radius: 3px;
    margin-right: 15px;
    position: relative;
}

.report_box .report_avatar img {
    width: 40px;
    height: 40px;
    max-height: 40px;
    border-radius: 3px;
    aspect-ratio: 1/1;
}

.report_box .report_name {
    display: inline-block;
}


.report_box .score_diary{
  position: absolute;
  display: inline-block;
  background-color: var(--un-primary-back-color);
  right: 0px;
  top: 4px;
  border-radius: 43px;
  color: white;
  font-size: 1rem;
  height: 15px;
  text-align: center;
  width: 15px;
  line-height: 1rem;
}
.report_box .score_diary.is-awards{
    background-color: rgba(255, 255, 255, 0.35);
        height: 25px!important;
    width: 25px!important;
}


.activity_item > .item .item_faza .faza_name{
  font-size: 0.6rem;
    color: white;
    height: 17px;
    display: flex;
    align-items: center;
    padding: 1px 3px;
}
.activity_item > .item .item_faza{
  margin-left: 5px;
    margin-right: 5px;
    color: #505050;
    display: inline-block;
    /* min-width: 23px; */
    text-align: center;
    border-radius: 3px;
    font-size: 1rem;
    margin-top: 0px;
    vertical-align: middle;
    display: inline-flex;
    align-items: center;
    height: 17px;
    gap: 5px;
    padding-right: 5px;
    font-size: 0.8rem;
}
.activity_item > .item .item_faza.faza_0 .faza_name{
    background-color: #a2da7a;
}
.activity_item > .item .item_faza.faza_1 .faza_name{
    background-color: #ff8855;
}
.activity_item > .item .item_faza.faza_2 .faza_name{
    background-color: #e35149;
}
.activity_item > .item .item_faza.faza_0{

    border: 1px #a2da7a solid;
    border-top: 1px #a2da7a solid;
}
.activity_item > .item .item_faza.faza_1{

    border: 1px #ff8855 solid;
    border-top: 1px #ff8855 solid;
}
.activity_item > .item .item_faza.faza_2{

    border: 1px #e35149 solid;
    border-top: 1px #e35149 solid;
}


/* USER CONTENT */


.activity_item > .user_box .user_img {
    width: 50px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
}

.activity_item > .user_box .user_img img {
    width: 100%;
    border-radius: 200px;
}

.activity_item > .user_box .user_img .user_range {
    position: absolute;
    bottom: 0px;
    right: -3px;
    font-size: 1.5rem;
    margin-right: 0px;
}

.activity_item > .user_box .user_name {
    display: inline-block;
    color: #4183c4;
}

.activity_item > .user_box .user_info {
    display: inline-block;
    width: calc(100% - 70px);
}


/**/


/* ADD BLOG */

.activity_item > .item .blog_one{
    display: table-cell;
    /* width: 50%; */
    /* vertical-align: middle; */
    width: 47%;
    vertical-align: middle;
    padding: 1rem 3% 1rem 0rem;
}
.activity_item > .item  .blog_one img{
    width: 100%;
    border-radius: 3px;
}
.activity_item > .item  .blog_two{
    display: table-cell;
    /* width: 50%; */
    /* vertical-align: middle; */
    width: 47%;
    vertical-align: middle;
    padding: 1rem 0px 1rem 3%;
}
.activity_item > .item  .blog_two .p{

}
.activity_item > .item .blog_two .h3{

}


/* UP STATUS */
.activity_item > .item .up_status {

}
.activity_item > .item .up_status .user_range{
        font-size: 2.5rem;
    vertical-align: middle;
    margin-top: -9px;
    margin-right: 10px;
}
.activity_item > .item .up_status .box_status span{

    margin-top: 7px;
    display: inline-block;
}
.activity_item > .item .up_status .box_status {
    display: inline-block;
    vertical-align: middle;
}

.activity_item > .item .up_status .box_status.old {

}

.activity_item > .item .up_status .box_status.new {

}

.activity_item > .item .up_status .icons {
    display: inline-block;
    margin: 0rem 1rem;
    color: #eeb948;
    font-size: 1.5em;
    vertical-align: middle;
}

@container pb (max-width: 600px){
  .activity_item .item_table{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .activity_item .item_table > .col_two,
  .activity_item .item_table > .col_three,
  .activity_item .item_table > .col_one{
    width: 100%;
  }
  .activity_item .item_table > .col_three  > * {
    width: 100%;
  }
}


</style>
